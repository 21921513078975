import { parse } from 'query-string';
import { Dispatch } from 'redux';

import {
    Themes,
    Locale,
    getValueFromKey,
    UnknownFunction,
} from '@eon-home/react-library';

import {
    UserApi,
    SaleModel,
    SignUpApi,
    GatewayApi,
    SaleEditModel,
    ResponseError,
    IotPairingModel,
    AuthenticationApi,
    UserPreferenceModel,
    PushNotificationApi,
    IoTAccountPairingApi,
    CampaignResponseModel,
    IotPairingVendorUrlModel,
    TenantAffiliateResponseModel,
    UserPushNotificationAddTokenModelOsEnum,
    GatewayMetadataModel,
    GatewayMetadataModelReboardingStateEnum,
    GatewaySkiResponseModel,
    GatewayConnectionResponseModel,
} from '@swagger-http';

import i18n from '@src/i18n';
import { analytics } from '@analytics';
import { Scope, Routes } from '@tools/enums';
import { BooleanString, Toast, SettingsAction } from '@store/types';
import { AdjustedIotVendorModel, PairingVendors } from '@tools/types';
import { getUserData, fetchCMSData, fetchSalesCMSData } from '@store/actions';
import {
    ToastType,
    ToastMessages,
    AuthActionTypes,
    UserActionTypes,
    SettingsActionTypes,
} from '@store/enums';
import {
    LOCALE,
    REGION,
    LANGUAGE_QUERY_PARAM,
    ACTIVATION_POPUP_SEEN,
    STEP_UP_LANGUAGE_QUERY_PARAM,
} from '@tools/constants';
import {
    getRegion,
    handleError,
    checkForScopes,
    getAccessToken,
    getDefaultLocale,
    getDefaultRegion,
    cleanUpLocalStorage,
    createRequestConfiguration,
} from '@tools/utils';

export const saveLocale = (
    locale: Locale | undefined,
    dispatch?: Dispatch<any>,
): void => {
    const currentLocale = localStorage.getItem(LOCALE);

    if (locale && locale === currentLocale) {
        return;
    }

    locale = locale || getDefaultLocale();

    // We might have old leftovers in the database
    if ((locale as string) === 'se') {
        locale = Locale.SE;
    }

    i18n.changeLanguage(locale);
    analytics.languageChanged(currentLocale || Locale.EN, locale);
    localStorage.setItem(LOCALE, locale);
    document.documentElement.setAttribute('lang', locale);

    if (dispatch) {
        dispatch(fetchCMSData(locale, getRegion()));
    }
};

export const saveRegion = (region: string | undefined): void => {
    localStorage.setItem(REGION, region || getDefaultRegion());
};

export const saveActivationPopupSeen = (seen: boolean | undefined): void => {
    localStorage.setItem(ACTIVATION_POPUP_SEEN, seen ? '1' : '0');
};

export const setToast = (payload: Toast) => (dispatch: Dispatch) => {
    dispatch({
        type: SettingsActionTypes.SET_TOAST,
        payload,
    });
};

export const hideToast = () => (dispatch: Dispatch) => {
    dispatch({
        type: SettingsActionTypes.SET_TOAST,
        payload: {
            visible: false,
        },
    });
};

type Nullable<T> = T | null;

export const setSuccessToast =
    (customText?: Nullable<ToastMessages | string>) => (dispatch: Dispatch) => {
        dispatch(hideToast());
        setTimeout(() => {
            dispatch(
                setToast({
                    type: ToastType.SUCCESS,
                    text: customText ?? ToastMessages.SUCCESS,
                    visible: true,
                }),
            );
        }, 400);
    };

export const setErrorToast =
    (customText?: Nullable<ToastMessages | string>) => (dispatch: Dispatch) => {
        dispatch(
            setToast({
                type: ToastType.ERROR,
                text: customText ?? ToastMessages.ERROR,
                visible: true,
            }),
        );
    };

export const getSetting = (
    settings: UserPreferenceModel[],
    key: string,
    value?: string,
): UserPreferenceModel | undefined | boolean => {
    const setting: UserPreferenceModel | undefined = settings.find(
        ({ id }: { id: string }) => id === key,
    );

    if (value && setting) {
        return getValueFromKey(setting, value) as
            | UserPreferenceModel
            | undefined
            | boolean;
    }

    return setting;
};

export const getSettings =
    () =>
    async (
        dispatch: Dispatch<any>,
    ): Promise<SettingsAction | UserPreferenceModel[] | void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        const userData = await dispatch(getUserData());

        return new UserApi(createRequestConfiguration())
            .userPreferences()
            .then((response: UserPreferenceModel[]) => {
                const locale = getSetting(
                    response,
                    'locale',
                    'value.lang',
                ) as unknown as Locale;

                const forceTadoUnlinkStatus = getSetting(
                    response,
                    'forceTadoUnlinkingCompleted',
                    'value.forceTadoUnlinkingCompleted',
                );

                const activationPopupSeen = getSetting(
                    response,
                    'stepUpRegisterOptionalActivationFlow',
                    'value.seen',
                );

                const mobileAppsBannerSeen = getSetting(
                    response,
                    'mobileAppsBannerSeen',
                    'value.seen',
                );

                const qualtrics = getSetting(
                    response,
                    'qualtricksMobileApp',
                    'value',
                ) || {
                    promptsCount: 0,
                    dateOfLastAction: new Date().getTime(),
                    isRequestAccepted: undefined,
                };

                // prettier-ignore
                const mobileWidgets = getSetting(response, 'expWidgetsMobileApp', 'value') || {};

                // prettier-ignore
                const loginCount = getSetting(response, 'loginCount', 'value') as unknown as { count: number } || 1;

                const mobileAppUsageCount = getSetting(
                    response,
                    'openingMobileApp',
                    'value.count',
                );

                const theme = getSetting(response, 'theme', 'value.theme');

                const referralProgramBannerSeen = getSetting(
                    response,
                    'referralProgramBannerSeen',
                    'value.seen',
                );

                // prettier-ignore
                const dismissedTourRoutes = getSetting(response, 'dismissedTourRoutes', 'value.routes') || [];

                // prettier-ignore
                const tourDisabled = getSetting(response, 'tourDisabled', 'value.disabled') || false;

                const completedSurveys = getSetting(
                    response,
                    'completedSurveys',
                    'value.completedSurveys',
                );

                const hungarianMarketing08052023 =
                    getSetting(
                        response,
                        'hungarianMarketing08052023',
                        'value.seen',
                    ) || false;

                const smartChargingInfoPopupSeen = getSetting(
                    response,
                    'smartChargingInfoPopupSeen',
                    'value.seen',
                );

                dispatch({
                    type: SettingsActionTypes.SET_TADO_UNLINK_STATUS,
                    payload: forceTadoUnlinkStatus,
                });

                dispatch({
                    type: SettingsActionTypes.SET_QUALTRICS_DATA,
                    payload: qualtrics,
                });

                dispatch({
                    type: SettingsActionTypes.SEE_ACTIVATION_POPUP,
                    payload: activationPopupSeen,
                });

                dispatch({
                    type: SettingsActionTypes.SEE_MOBILE_APPS_BANNER,
                    payload: mobileAppsBannerSeen,
                });

                dispatch({
                    type: SettingsActionTypes.SET_THEME,
                    payload: theme,
                });

                dispatch({
                    type: UserActionTypes.SET_USER_DATA,
                    payload: {
                        loginCount:
                            typeof loginCount === 'object'
                                ? loginCount.count
                                : loginCount,
                    },
                });

                dispatch({
                    type: SettingsActionTypes.SET_MOBILE_WIDGETS_DATA,
                    payload: mobileWidgets,
                });

                dispatch({
                    type: SettingsActionTypes.SEE_REFERRAL_PROGRAM,
                    payload: referralProgramBannerSeen,
                });

                dispatch({
                    type: SettingsActionTypes.DISMISS_TOUR_ROUTE,
                    payload: dismissedTourRoutes,
                });

                dispatch({
                    type: SettingsActionTypes.DISABLE_TOUR,
                    payload: tourDisabled,
                });

                dispatch({
                    type: UserActionTypes.SET_COMPLETED_CMS_SURVEYS,
                    payload: completedSurveys || [],
                });

                dispatch({
                    type: UserActionTypes.SET_MOBILE_APP_USAGE_COUNT,
                    payload: mobileAppUsageCount || 0,
                });

                dispatch({
                    type: SettingsActionTypes.SEE_HUNGARIAN_MARKETING_WIDGET,
                    payload: hungarianMarketing08052023,
                });

                dispatch({
                    type: UserActionTypes.SET_SMART_CHARGING_INFO_POPUP_SEEN,
                    payload: smartChargingInfoPopupSeen || false,
                });

                saveLocale(locale, dispatch);
                saveActivationPopupSeen(!!activationPopupSeen);

                return Promise.resolve({
                    ...response,
                    userData,
                });
            })
            .catch(async (e: ResponseError) =>
                Promise.reject(
                    await handleError(e, 'Error when getting the settings:'),
                ),
            );
    };

export const setLocale =
    (locale: Locale) =>
    (dispatch: Dispatch<any>): Promise<SettingsAction | void> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'locale',
                userPreferenceAddModel: {
                    value: [{ lang: locale }],
                },
            })
            .then(() => {
                saveLocale(locale, dispatch);
            })
            .catch(async (е: ResponseError) => {
                await handleError(е, 'Error when saving the locale:');
            });
    };

export const setUiTheme =
    (value: Themes) =>
    (dispatch: Dispatch<any>): Promise<SettingsAction | void> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'theme',
                userPreferenceAddModel: {
                    value: [{ theme: value }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.SET_THEME,
                    payload: value,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when changing the UI theme:');
            });
    };

export const removePairing =
    (
        vendor: PairingVendors,
        onSuccess: UnknownFunction,
        onError?: UnknownFunction,
    ) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.SMARTHOME_PAIRING_WRITE])) {
            return Promise.resolve();
        }

        const errorMessage: string = 'Error when removing provider: ';

        return new IoTAccountPairingApi(createRequestConfiguration())
            .ioTAccountPairingDeletePairing({
                vendor,
            })
            .then(async () => {
                if (typeof onSuccess === 'function') {
                    dispatch(setSuccessToast());
                    return onSuccess();
                } else {
                    return Promise.reject(
                        await handleError(
                            new ResponseError(
                                new Response(
                                    'No callback present when unlinking an IoT vendor.',
                                ),
                            ),
                            errorMessage + ' callback not present',
                        ),
                    );
                }
            })
            .catch(async (e: ResponseError) => {
                if (typeof onError === 'function') {
                    onError();
                }
                Promise.reject(await handleError(e, errorMessage));
            });
    };

export const requestLogout =
    (cleanUp?: UnknownFunction) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        cleanUpLocalStorage();

        dispatch({
            type: AuthActionTypes.INITIATE_LOGOUT,
        });

        return new AuthenticationApi(createRequestConfiguration())
            .authenticationLogout(
                {
                    authorization: 'Basic ' + getAccessToken(),
                },
                {
                    credentials: 'include',
                },
            )
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when logging out:');
            })
            .finally(() => {
                cleanUpLocalStorage();
                typeof cleanUp === 'function' && cleanUp();
            });
    };

export const getPairingDataPerProvider = async (
    vendor: string,
): Promise<IotPairingVendorUrlModel | void> => {
    if (!checkForScopes([Scope.SMARTHOME_PAIRING_READ])) {
        return Promise.resolve();
    }

    try {
        const result = await new IoTAccountPairingApi(
            createRequestConfiguration(),
        ).ioTAccountPairingGetVendorConfig({
            vendor,
        });

        return result;
    } catch (e) {
        return Promise.reject(
            await handleError(e, 'Error when getting IoT provider URL:'),
        );
    }
};

export const getPairingUrlPerProvider = async (
    vendor: string,
): Promise<string | void> => {
    if (!checkForScopes([Scope.SMARTHOME_PAIRING_READ])) {
        return Promise.resolve();
    }

    try {
        const url = await new IoTAccountPairingApi(createRequestConfiguration())
            .ioTAccountPairingGetVendorConfig({
                vendor,
            })
            .then((result) => result.url)
            .catch(() => '');

        return url;
    } catch (e) {
        return Promise.reject(
            await handleError(e, 'Error when getting IoT provider URL:'),
        );
    }
};

export const getProviders = async (dispatch?: Dispatch) => {
    if (!checkForScopes([Scope.SMARTHOME_PAIRING_READ])) {
        return;
    }

    try {
        const providers: AdjustedIotVendorModel[] =
            (await new IoTAccountPairingApi(
                createRequestConfiguration(),
            ).ioTAccountPairingGetSupportedVendors()) as AdjustedIotVendorModel[];

        if (dispatch) {
            dispatch({
                type: SettingsActionTypes.SET_PROVIDERS,
                payload: providers,
            });
        }

        return providers;
    } catch (e) {
        return Promise.reject(
            await handleError(e, 'Error when getting IoT providers:'),
        );
    }
};

export const fetchProviders =
    () =>
    (dispatch: Dispatch<any>): void => {
        getProviders(dispatch);
    };

export const setAccountPairing = (
    state: string,
    code: string,
    pairingCode?: string,
    onSuccess?: UnknownFunction,
): Promise<void | IotPairingModel[]> => {
    if (!checkForScopes([Scope.SMARTHOME_PAIRING_WRITE])) {
        return Promise.resolve();
    }

    return new IoTAccountPairingApi(createRequestConfiguration())
        .ioTAccountPairingSetPairVendor({
            iotPairingModifyModel: { state, code, pairingCode },
        })
        .then(() => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            return Promise.resolve(getProviders());
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error when connecting a provider:');
            return Promise.reject(e.response);
        });
};

export const getLanguageFromParam = (): Locale => {
    const queryString = parse(location.search);

    // prettier-ignore
    return (
        getValueFromKey(queryString, LANGUAGE_QUERY_PARAM) ||
        getValueFromKey(queryString, STEP_UP_LANGUAGE_QUERY_PARAM)
    ) as Locale;
};

export const getLocale = (): Locale => {
    const locales = Object.values(Locale);

    if (!getAccessToken()) {
        let localeAsParam = getLanguageFromParam() as Locale | 'se';

        // Use both options for Swedish
        if (localeAsParam === 'se') {
            localeAsParam = Locale.SE;
        }

        if (localeAsParam && locales.includes(localeAsParam)) {
            saveLocale(localeAsParam);
            return localeAsParam;
        }
    }

    const savedLocale = localStorage.getItem(LOCALE);

    if (savedLocale && locales.includes(savedLocale as any)) {
        return savedLocale as Locale;
    }

    const defaultLocale = getDefaultLocale();

    saveLocale(defaultLocale);

    return defaultLocale;
};

export const seeActivationPopup =
    (seen: boolean = true) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'stepUpRegisterOptionalActivationFlow',
                userPreferenceAddModel: {
                    value: [{ seen }],
                },
            })
            .then(() => {
                saveActivationPopupSeen(seen);

                dispatch({
                    type: SettingsActionTypes.SEE_ACTIVATION_POPUP,
                    payload: seen,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when setting activation popup to seen:',
                );
            });
    };

export const saveNotificationsToken = async (
    token: string,
    uuid: string,
): Promise<Response | void> => {
    if (!checkForScopes([Scope.ME_WRITE])) {
        return Promise.resolve();
    }

    return new PushNotificationApi(createRequestConfiguration())
        .pushNotificationAddUpdatePushNotificationToken({
            userPushNotificationAddTokenModel: {
                os: UserPushNotificationAddTokenModelOsEnum.Web,
                uuid,
                token,
            },
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Unable to save push notifications token: ');
        });
};

export const getSales =
    (archive: BooleanString = 'false') =>
    (dispatch: Dispatch<any>): Promise<SettingsAction | void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        dispatch({
            type: SettingsActionTypes.SET_SALES_LOADING,
            payload: true,
        });

        return new UserApi(createRequestConfiguration())
            .userGetSales({
                completed: archive,
            })
            .then((payload: SaleModel[]) => {
                const action = {
                    type:
                        archive === 'true'
                            ? SettingsActionTypes.GET_SALES_ARCHIVE
                            : SettingsActionTypes.GET_SALES,
                    payload,
                };

                dispatch(fetchSalesCMSData(getRegion()));

                dispatch(action);

                return action;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when getting sales:');
            })
            .finally(() => {
                dispatch({
                    type: SettingsActionTypes.SET_SALES_LOADING,
                    payload: false,
                });
            });
    };

export const patchSale =
    (saleId: string, body: SaleEditModel, archive: boolean = false) =>
    (dispatch: Dispatch<any>): Promise<SettingsAction | void> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        dispatch({
            type: SettingsActionTypes.SET_SALES_LOADING,
            payload: true,
        });

        return new UserApi(createRequestConfiguration())
            .userPatchSale({
                saleId,
                saleEditModel: body,
            })
            .then((payload: SaleModel) => {
                const action = {
                    type: archive
                        ? SettingsActionTypes.UPDATE_SALE_ARCHIVE
                        : SettingsActionTypes.UPDATE_SALE,
                    payload,
                };

                dispatch(action);

                return action;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when updating sales:');
            })
            .finally(() => {
                dispatch({
                    type: SettingsActionTypes.SET_SALES_LOADING,
                    payload: false,
                });
            });
    };

export const getTenantsDetails =
    () =>
    (dispatch: Dispatch): Promise<void> =>
        new SignUpApi(createRequestConfiguration())
            .signUpGetTenantAffiliateAssignment()
            .then((tenantsDetails: TenantAffiliateResponseModel[]) => {
                dispatch({
                    type: SettingsActionTypes.SET_TENANTS_DETAILS,
                    payload: tenantsDetails,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error while getting tenants details.');
            });

export const getTenantConfig =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        if (!checkForScopes([Scope.ME_READ])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userGetTenantAffiliateAssignment()
            .then((tenantConfig: TenantAffiliateResponseModel) => {
                dispatch({
                    type: SettingsActionTypes.SET_TENANT_CONFIG,
                    payload: tenantConfig,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error while getting tenants details.');
            });
    };

export const setFeedbackProvided =
    (isRequestAccepted: boolean, promptsCount: number) =>
    (dispatch: Dispatch): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve(false);
        }

        const qualtrics = {
            isRequestAccepted,
            dateOfLastAction: new Date().getTime(),
            promptsCount: promptsCount + 1,
        };

        dispatch({
            type: SettingsActionTypes.SET_QUALTRICS_DATA,
            payload: qualtrics,
        });

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'qualtricksMobileApp',
                userPreferenceAddModel: {
                    value: [qualtrics],
                },
            })
            .then(() => Promise.resolve())
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error setting qualtrics data.');
            });
    };

export const updateMobileWidgets =
    (data: Record<string, any>) => (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'expWidgetsMobileApp',
                userPreferenceAddModel: {
                    value: [data],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.SET_MOBILE_WIDGETS_DATA,
                    payload: data,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when setting mobile widget to seen:',
                );
            });
    };

export const setReferralProgramSeen =
    (seen: boolean = true) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'referralProgramBannerSeen',
                userPreferenceAddModel: {
                    value: [{ seen }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.SEE_REFERRAL_PROGRAM,
                    payload: seen,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when setting referral program banner to seen:',
                );
            });
    };

export const setDismissedTourRoutes =
    (routes: Routes[]) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'dismissedTourRoutes',
                userPreferenceAddModel: {
                    value: [{ routes }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.DISMISS_TOUR_ROUTE,
                    payload: routes,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when setting dismissed tour routes:',
                );
            });
    };

export const setTourDisabled =
    (disabled: boolean) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'tourDisabled',
                userPreferenceAddModel: {
                    value: [{ disabled }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.DISABLE_TOUR,
                    payload: disabled,
                });

                dispatch(setSuccessToast());
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when setting tour disabled state:');
            });
    };

export const getCampaignDetails =
    (campaignUUID: string) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({
            type: SettingsActionTypes.SET_CAMPAIGN_DETAILS,
            payload: {
                error: false,
                loading: true,
            },
        });

        return new SignUpApi(createRequestConfiguration())
            .signUpGetCampaign({
                campaignUUID,
            })
            .then((data: CampaignResponseModel) => {
                dispatch({
                    type: SettingsActionTypes.SET_CAMPAIGN_DETAILS,
                    payload: { data },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error getting campaign details:');

                dispatch({
                    type: SettingsActionTypes.SET_CAMPAIGN_DETAILS,
                    payload: {
                        error: true,
                    },
                });
            })
            .finally(() => {
                dispatch({
                    type: SettingsActionTypes.SET_CAMPAIGN_DETAILS,
                    payload: {
                        loading: false,
                    },
                });
            });
    };

export const setHungarianMarketingWidgetSeen =
    (seen: boolean) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'hungarianMarketing08052023',
                userPreferenceAddModel: {
                    value: [{ seen }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.SEE_HUNGARIAN_MARKETING_WIDGET,
                    payload: seen,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when setting tour disabled state:');
            });
    };

export const setForceTadoUnlinkingCompleted =
    (value: boolean = true) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'forceTadoUnlinkingCompleted',
                userPreferenceAddModel: {
                    value: [{ forceTadoUnlinkingCompleted: value }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.SET_TADO_RELINKED,
                    payload: value,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when updating flag forceTadoUnlinkingCompleted',
                );
            });
    };

export const setSmartChargingInfoPopupSeen =
    (seen: boolean = true) =>
    (dispatch: Dispatch<any>): Promise<any> => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        return new UserApi(createRequestConfiguration())
            .userUpdatePreference({
                key: 'smartChargingInfoPopupSeen',
                userPreferenceAddModel: {
                    value: [{ seen }],
                },
            })
            .then(() => {
                dispatch({
                    type: SettingsActionTypes.SET_SMART_CHARGING_INFO_POPUP_SEEN,
                    payload: seen,
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when setting smart charging helper widget seen',
                );
            });
    };

type GridSupportiveControlCopyId = {
    feedback: string;
};

export const setGridSupportiveControlCopyId =
    ({ feedback }: GridSupportiveControlCopyId) =>
    (dispatch: Dispatch<any>) => {
        if (!checkForScopes([Scope.ME_WRITE])) {
            return Promise.resolve();
        }

        dispatch(setSuccessToast(feedback));
        return Promise.resolve();
    };

export const getGatewayConnectionStatus =
    (installationId: string) => async (): Promise<object | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_GATEWAY_READ])) {
            return;
        }

        return await new GatewayApi(createRequestConfiguration())
            .gatewayGetConnectionStatus({ installationId })
            .then((data: GatewayConnectionResponseModel) => {
                const connectionStatus = data?.connectionStatus;
                return connectionStatus;
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error getting gateway connection status:',
                );
            });
    };

export const getGatewaySkiId =
    (installationId: string) => async (): Promise<string | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_GATEWAY_READ])) {
            return;
        }

        return await new GatewayApi(createRequestConfiguration())
            .gatewayGetSkiId({ installationId })
            .then((data: GatewaySkiResponseModel) => {
                const skiId = data?.skiId;
                return skiId;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error getting gateway skiid:');
            });
    };

export const getGatewayMetadata =
    (installationId: string) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_GATEWAY_READ])) {
            return Promise.resolve();
        }

        return new GatewayApi(createRequestConfiguration())
            .gatewayGetMetadata({ installationId })
            .then((data: GatewayMetadataModel) => {
                const isGatewayRescanning =
                    data?.reboardingState ===
                    GatewayMetadataModelReboardingStateEnum.Pending;

                dispatch({
                    type: SettingsActionTypes.SET_GRIDX_GATEWAY_RESCAN,
                    payload: {
                        isGatewayRescanning: isGatewayRescanning,
                        data: data,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error getting gateway metadata:');
            });
    };

export const setGatewayMetadata =
    (
        installationId: string,
        rescanState: GatewayMetadataModelReboardingStateEnum,
    ) =>
    async (dispatch: Dispatch<any>): Promise<void | boolean> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_GATEWAY_WRITE])) {
            return Promise.resolve(false);
        }

        return new GatewayApi(createRequestConfiguration())
            .gatewaySetMetadata({
                installationId,
                gatewayMetadataModel: { reboardingState: rescanState },
            })
            .then((data: GatewayMetadataModel) => {
                const isGatewayRescanning =
                    data?.reboardingState ===
                    GatewayMetadataModelReboardingStateEnum.Pending;

                dispatch({
                    type: SettingsActionTypes.SET_GRIDX_GATEWAY_RESCAN,
                    payload: {
                        isGatewayRescanning: isGatewayRescanning,
                        data: data,
                    },
                });

                return true;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error setting gateway metadata:');

                return false;
            });
    };
