/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  GatewayConnectionResponseModel,
  GatewayDeviceInstallation,
  GatewayInvertersRequestModel,
  GatewayInvertersResponseModel,
  GatewayMetadataModel,
  GatewayRequestModel,
  GatewaySkiResponseModel,
  Unauthorized,
} from '../models';

export interface GatewayAddGatewayRequest {
    gatewayRequestModel: GatewayRequestModel;
}

export interface GatewayGetConnectionStatusRequest {
    installationId: string;
}

export interface GatewayGetMetadataRequest {
    installationId: string;
}

export interface GatewayGetSkiIdRequest {
    installationId: string;
}

export interface GatewayScanGatewayRequest {
    installationId: string;
}

export interface GatewaySetMetadataRequest {
    installationId: string;
    gatewayMetadataModel: GatewayMetadataModel;
}

export interface GatewayUpdateGatewayInvertersRequest {
    gatewayInvertersRequestModel: GatewayInvertersRequestModel;
}

/**
 * 
 */
export class GatewayApi extends runtime.BaseAPI {

    /**
     * Add Gateway Device
     */
    async gatewayAddGatewayRaw(requestParameters: GatewayAddGatewayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayDeviceInstallation>> {
        if (requestParameters.gatewayRequestModel === null || requestParameters.gatewayRequestModel === undefined) {
            throw new runtime.RequiredError('gatewayRequestModel','Required parameter requestParameters.gatewayRequestModel was null or undefined when calling gatewayAddGateway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.gatewayRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add Gateway Device
     */
    async gatewayAddGateway(requestParameters: GatewayAddGatewayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayDeviceInstallation> {
        const response = await this.gatewayAddGatewayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Gateway Inverters
     */
    async gatewayFetchGatewayInvertersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GatewayInvertersResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/inverters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all Gateway Inverters
     */
    async gatewayFetchGatewayInverters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GatewayInvertersResponseModel>> {
        const response = await this.gatewayFetchGatewayInvertersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get gateway connection status
     */
    async gatewayGetConnectionStatusRaw(requestParameters: GatewayGetConnectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayConnectionResponseModel>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling gatewayGetConnectionStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/{installationId}/status`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get gateway connection status
     */
    async gatewayGetConnectionStatus(requestParameters: GatewayGetConnectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayConnectionResponseModel> {
        const response = await this.gatewayGetConnectionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Gateway Devices
     */
    async gatewayGetGatewayRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GatewayDeviceInstallation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Gateway Devices
     */
    async gatewayGetGateway(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GatewayDeviceInstallation>> {
        const response = await this.gatewayGetGatewayRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a gateway metadata detail
     */
    async gatewayGetMetadataRaw(requestParameters: GatewayGetMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayMetadataModel>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling gatewayGetMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/{installationId}/metadata`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get a gateway metadata detail
     */
    async gatewayGetMetadata(requestParameters: GatewayGetMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayMetadataModel> {
        const response = await this.gatewayGetMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get gateway SKI ID
     */
    async gatewayGetSkiIdRaw(requestParameters: GatewayGetSkiIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewaySkiResponseModel>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling gatewayGetSkiId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/{installationId}/skiId`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get gateway SKI ID
     */
    async gatewayGetSkiId(requestParameters: GatewayGetSkiIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewaySkiResponseModel> {
        const response = await this.gatewayGetSkiIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Gateway Devices
     */
    async gatewayScanGatewayRaw(requestParameters: GatewayScanGatewayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling gatewayScanGateway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/{installationId}/scan`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Scan Gateway Devices
     */
    async gatewayScanGateway(requestParameters: GatewayScanGatewayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.gatewayScanGatewayRaw(requestParameters, initOverrides);
    }

    /**
     * Set a metadata key to a gateway
     */
    async gatewaySetMetadataRaw(requestParameters: GatewaySetMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayMetadataModel>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling gatewaySetMetadata.');
        }

        if (requestParameters.gatewayMetadataModel === null || requestParameters.gatewayMetadataModel === undefined) {
            throw new runtime.RequiredError('gatewayMetadataModel','Required parameter requestParameters.gatewayMetadataModel was null or undefined when calling gatewaySetMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/{installationId}/metadata`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.gatewayMetadataModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set a metadata key to a gateway
     */
    async gatewaySetMetadata(requestParameters: GatewaySetMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayMetadataModel> {
        const response = await this.gatewaySetMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Gateway Inverters
     */
    async gatewayUpdateGatewayInvertersRaw(requestParameters: GatewayUpdateGatewayInvertersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GatewayInvertersResponseModel>>> {
        if (requestParameters.gatewayInvertersRequestModel === null || requestParameters.gatewayInvertersRequestModel === undefined) {
            throw new runtime.RequiredError('gatewayInvertersRequestModel','Required parameter requestParameters.gatewayInvertersRequestModel was null or undefined when calling gatewayUpdateGatewayInverters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/gateway/inverters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.gatewayInvertersRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update Gateway Inverters
     */
    async gatewayUpdateGatewayInverters(requestParameters: GatewayUpdateGatewayInvertersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GatewayInvertersResponseModel>> {
        const response = await this.gatewayUpdateGatewayInvertersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
